import React from 'react'
import IconsSwitch from 'src/components/IconsSwitch/_index'

import * as S from './styles'

type CardWithLinkProps = {
  icon: string;
  title: string;
  description: string;
}

const CardWithButton: React.FC<CardWithLinkProps> = ({ icon, title, description }: CardWithLinkProps) => {
  return (
    <S.CardWithButton>
      <div>
        <IconsSwitch className='card__icon' icon={icon} color='var(--gray500)' lib='interco' width='24' height='24' />
      </div>
      <div className='card__text-container'>
        <h3 className='card__title'>{title}</h3>
        <p className='card__description'>{description}</p>
      </div>
    </S.CardWithButton>
  )
}

export default CardWithButton
